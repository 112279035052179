import React from 'react'

import Layout from '../components/Layout'
import Plan from '../components/Plan'
import PlanSelector from '../components/PlanSelector'
import PlanFeature from '../components/PlanFeature'

const PlansPricingPage = () => {
  const plans = configurePlans()
  const { contractPlans, nonContractPlans } = plans
  const [
    selectedContractTypePlanProps,
    setselectedContractTypePlanProps,
  ] = React.useState(contractPlans)

  function onPlanChange(filterByContractPlans) {
    setselectedContractTypePlanProps(
      filterByContractPlans ? contractPlans : nonContractPlans
    )
  }

  // TODO: Move this monstrosity into a static plans.json file and load through graphql
  function configurePlans() {
    const sharedContractPlansProps = {
      banner: true,
      cisUrl: '/pdf/tomi-cis-unlimited-nbn-contract-oct-2022.pdf',
      optionalPlanCostCopy: '',
    }

    const sharedNonContractPlansProps = {
      banner: false,
      promotionalPricing: null,
      cisUrl: '/pdf/tomi-cis-unlimited-nbn-contract-oct-2022.pdf',
      optionalPlanCostCopy: '',
    }

    return {
      contractPlans: [
        {
          ...sharedContractPlansProps,
          id: 'UN-L-NBN25/5-24M-3',
          name: 'Tomi Home<br />Basic',
          blurb: 'Basic',
          price: '59.95',
          minimumTotalCost: '200.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 22/4 Mbps',
        },
        {
          ...sharedContractPlansProps,
          id: 'UN-SF-NBN50/20-24M-4',
          name: 'Tomi Home<br />Standard<br />for $59.95',
          highlighted: true,
          blurb: 'Standard',
          price: '59.95',
          promotionalPricing: (
            <>
              <p className="text-bold-secondary">FOR FIRST 6 MONTHS THEN</p>
              <small className="promo-plan-price promo-plan-price--pricing-table">
                $69.95
              </small>
            </>
          ),
          minimumTotalCost: '210.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 48/17 Mbps',
        },
        {
          ...sharedContractPlansProps,
          id: 'UN-HY-NBN100/20-24M-2',
          name: 'Tomi Home<br />Fast<br />',
          blurb: 'Fast',
          price: '89.95',
          minimumTotalCost: '230.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 95/17 Mbps',
        },
        {
          ...sharedContractPlansProps,
          id: 'UN-HY-NBN250/25-24M-1',
          name: 'Tomi Home<br />Super Fast<br />',
          blurb: 'Super Fast',
          price: '109.95',
          minimumTotalCost: '250.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 240/21 Mbps',
        },
        {
          ...sharedContractPlansProps,
          id: 'UN-HY-NBN1000/50-24M-1',
          name: 'Tomi Home<br />Ultra Fast<br />',
          blurb: 'Ultra Fast',
          price: '139.95',
          minimumTotalCost: '280.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 500/42 Mbps',
        },
      ],
      nonContractPlans: [
        {
          ...sharedNonContractPlansProps,
          id: 'UN-L-NBN25/5-0M-3',
          name: 'Tomi Home<br />Basic',
          blurb: 'Basic',
          price: '59.95',
          minimumTotalCost: '59.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 22/4 Mbps',
        },
        {
          ...sharedNonContractPlansProps,
          id: 'UN-SF-NBN50/20-0M-4',
          highlighted: true,
          name: 'Tomi Home<br />Standard',
          blurb: 'Standard',
          price: '69.95',
          minimumTotalCost: '69.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 48/17 Mbps',
        },
        {
          ...sharedNonContractPlansProps,
          id: 'UN-HY-NBN100/20-0M-2',
          name: 'Tomi Home<br />Fast<br />',
          blurb: 'Fast',
          price: '89.95',
          minimumTotalCost: '89.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 95/17 Mbps',
        },
        {
          ...sharedNonContractPlansProps,
          id: 'UN-HY-NBN250/25-0M-1',
          name: 'Tomi Home<br />Super Fast<br />',
          blurb: 'Super Fast',
          price: '109.95',
          minimumTotalCost: '109.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 240/21 Mbps',
        },
        {
          ...sharedNonContractPlansProps,
          id: 'UN-HY-NBN1000/50-0M-1',
          name: 'Tomi Home<br />Ultra Fast<br />',
          blurb: 'Ultra Fast',
          price: '139.95',
          minimumTotalCost: '139.95',
          typicalEveningSpeeds:
            'Typical Evening Download/Upload Speed 500/42 Mbps',
        },
      ],
    }
  }

  return (
    <Layout>
      <main>
        <section className="plans">
          <div className="container">
            <h1 className="page-title">Plans and Pricing</h1>
            <PlanSelector onPlanChange={onPlanChange} />
          </div>

          <section className="plan-table">
            <div className="container">
              {selectedContractTypePlanProps.reduce(
                (acc, planProps) => [
                  ...acc,
                  <Plan key={planProps.blurb} {...planProps} />,
                ],
                []
              )}
            </div>
          </section>

          <PlanFeature />
        </section>
      </main>
    </Layout>
  )
}

export default PlansPricingPage
