import React from 'react'

const PlanFeature = () => (
  <div className="container">
    <section className="plan-details">
      <h2>Included with all plans</h2>

      <hr />

      <div className="row">
        <div className="col-md-6">
          <ul>
            <li>Fibre broadband speeds</li>
            <li>No speed shaping</li>
            <li>No Peak or Off-Peak Limits</li>
          </ul>
        </div>

        <div className="col-md-6">
          <ul>
            <li>Pre-configured WiFi network</li>
            <li>Fast activation</li>
          </ul>
        </div>
      </div>

      <hr />

      <div className="row mb-5">
        <div className="col-sm-4">
          <h3>Competitive pricing</h3>
          <p>
            Our plans are designed with real people in mind. And we know real
            people want flexibility and value for money.
          </p>
        </div>

        <div className="col-sm-4">
          <h3>Fast, friendly support</h3>
          <p>
            If you need technical help, our team is on-call seven-days a week.
            We’re always happy to hear from you.
          </p>
        </div>

        <div className="col-sm-4">
          <h3>No hidden fees, no surprises</h3>
          <p>
            Track your usage using our self-service portal and you’ll always
            know exactly what you’re spending.
          </p>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-sm-12">
          <p>
            <strong>COVID-19 Financial Assistance</strong>: We're supporting our
            customers who are impacted by COVID-19, including financial
            assistance.
          </p>
          <p>
            If you are impacted, please contact us on 1300 655 982 Mon-Fri
            between 9am-5pm AEST.
          </p>
          <p>
            <strong>COVID-19 Connection Assistance</strong>: We're supporting
            families with school age children needing to access online learning.
          </p>
          <p>
            If you are impacted, please contact us on 1300 655 982 Mon-Fri
            between 9am-5pm AEST.
          </p>
        </div>
      </div>
    </section>
  </div>
)

export default PlanFeature
