import React from 'react'
import PropTypes from 'prop-types'

const PlanSelector = props => {
  const [planSelectorContract, setplanSelectorContract] = React.useState(true)

  function onPlanChange(isContract) {
    const { onPlanChange } = props

    setplanSelectorContract(isContract)
    onPlanChange(isContract)
  }

  return (
    <section className="plan-selector-module">
      <div className="plan-selector">
        <button
          onClick={() => onPlanChange(true)}
          className={planSelectorContract ? 'active' : ''}
        >
          Contract
        </button>
        <button
          onClick={() => onPlanChange(false)}
          className={!planSelectorContract ? 'active' : ''}
        >
          Non Contract
        </button>
        <span
          className={`animated-selector ${
            planSelectorContract ? '' : 'non-contract'
          }`}
        />
      </div>
    </section>
  )
}

PlanSelector.propTypes = {
  onPlanChange: PropTypes.func.isRequired,
}

export default PlanSelector
